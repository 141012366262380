<template>
  <section id="workflow-users-list">
    <div class="vue-data-table-default p-4">
      <data-tables-server
        :data="data"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pagination-props="paginationProps"
        @query-change="loadData"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        :element-loading-text="loadingText"
      >

      <el-table-column
  v-for="column in columns"
  :key="column.key"
  :label="column.label"
  :prop="column.key"
  :width="200"
>
  <template #default="{ row }">
    <div v-if="column.key === 'picture'">
      <img 
        :src="row[column.key]" 
        alt="Image"
        style="width: 100px; height: 100px; object-fit: cover; border-radius: 8px;"
      />
    </div>
    <div v-else>
      {{ row[column.key] }}
    </div>
  </template>
</el-table-column>


      </data-tables-server>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { postAPICall } from "@/helpers/httpHelper";

export default {
  name: "IntegrationTable",
  components: {},
  props: {
    componentData: Object,
    integrationDetails: Object,
    customFilters: Array,
  },
  mixins: [],
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 0,
      data: [],
      loading: false,
      loadingText: "Loading...",
      columns: [],
      showTable: false,
    };
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
  },
  async mounted() {
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    await this.getNecessaryInfo();
  },
  methods: {
    async loadData(data) {
      if (this.data && this.data.length) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchAllData();
      }
    },
    async getNecessaryInfo() {
      this.prepareColumns();
      this.fetchAllData();
    },
    prepareColumns() {
      this.columns = [];
      if (this.integrationDetails?.matchingEvents?.settings?.availableColumns) {
        this.columns =
          this.integrationDetails.matchingEvents.settings.availableColumns ||
          [];
      }
    },
    async fetchAllData() {
      console.log("integrationDetails", this.integrationDetails);
      console.log("componentData", this.componentData);
      console.log("customFilters", this.customFilters);

      let connection_id =
        this.integrationDetails?.matchedApplication?.integration
          ?.connection_id || "";

      if (!connection_id) {
        this.$notify({
          title: "Error",
          message:
            "Connection ID is missing. Please ensure the integration is properly set up.",
          type: "error",
        });
        this.loading = false;
        return;
      }
      this.loading = true;
      const availableColumns =
  this.integrationDetails?.matchingEvents?.settings?.availableColumns || [];

const fields =
  this.integrationDetails?.matchingEvents?.settings?.supportType === 'table' && availableColumns.length > 0
    ? availableColumns.map((e) => e.key)
    : undefined; 
      try {
        const pagination = {
    page: this.currentPage ,
    limit: this.pageSize 
  };
        const response = await postAPICall(
          "POST",
          "integrations/service/fetch-data",
          {
            asset: this.integrationDetails?.matchingEvents?.asset,
            provider: this.integrationDetails?.matchedApplication?.key,
            // field: this.integrationDetails?.matchingEvents?.eventKey,
            connectionId: connection_id,
            dependencies: this.componentData.dependencies || {},
            ...this.componentData.dates,
            fields: fields,
            pagination:pagination
          }
        );

        console.log("API Response:", response);

        this.data = response.data.rows || [];
        if (this.data.length) {
          this.total = response.data.metadata.total_records || 0;
          this.loading = true;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        this.$notify({
          title: "Error",
          message: "Failed to fetch data. Please try again.",
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.approver-text {
  color: #3366cc;
}
.list-style {
  margin-top: 10px;
}
.expired-div {
  color: #dc3545;
}
.dull-text {
  color: #888;
  opacity: 0.8;
  overflow-wrap: break-word;
  word-break: normal;
}
.log-expired {
  color: #dc3545;
}
.resend-form-style {
  width: 70px;
  height: 25px;
}
</style>
